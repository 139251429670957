import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Rnd } from 'react-rnd'
import { getAnnotationNameFormType } from '../../utils'

import './styles.scss'
import cx from 'classnames'

const FIXED_AREA = 100
const ZOOMIN_STEP = 0.1
const MOVING_STEP = 5

const NAVIGATION_WIDTH = 400
const CONTENT_PADDING = 20

function roundUpToMultiple(number, multiple) {
	const intNumber = parseInt(number, 10)
	const intMultiple = parseInt(multiple, 10)

	return intNumber + intMultiple - (intNumber % intMultiple)
}

const ImageWithCanvas = props => {
	const canvasRef = useRef(null)
	const contextRef = useRef(null)
	const imageContainerRef = useRef(null)
	const imageRef = useRef(null)
	const originalImageRef = useRef(null)
	const [imageDimension, setImageDimension] = useState({ width: 0, height: 0 }) // this is important only for default annotation position

	const [alignHeight, setAlignHeight] = useState(false)
	const [isDrawing, setIsDrawing] = useState(false)

	const canvasOffSetX = useRef(null)
	const canvasOffSetY = useRef(null)
	const startX = useRef(null)
	const startY = useRef(null)
	const [markedWidth, setMarkedWidth] = useState(0)
	const [markedHeight, setMarkedHeight] = useState(0)

	const [selectedAnnotationId, setSelectedAnnotationId] = useState(0)

	// zooming
	const scale = useRef(1)
	const translateX = useRef(0)
	const translateY = useRef(0)

	// annotations
	const annotationsRef = useRef([])
	const savedAnnotationsRef = useRef({})

	// original image
	const [isAnnotationImageLoaded, setIsAnnotationImageLoaded] = useState(false)
	const [isOriginalImageLoaded, setIsOriginalImageLoaded] = useState(false)

	useEffect(() => {
		annotationsRef.current = annotationsRef.current.slice(0, props.selectedAreas.length)
	}, [props.selectedAreas])

	const isLoading = !isAnnotationImageLoaded || !isOriginalImageLoaded

	const loadingCSS = cx('ImageWithCanvas__loading', {
		'ImageWithCanvas__loading--hidden': !isLoading,
	})
	const imageSideBySideWrapperCSS = cx('ImageWithCanvas__imgSideBySideWrapper', {
		'ImageWithCanvas__imgSideBySideWrapper--withLeftNavigation': props.isNavigationOpened,
	})
	const imageContainerCSS = cx({
		ImageWithCanvas__imgContainer: !alignHeight,
		'ImageWithCanvas__imgContainer--maxContent': alignHeight,
		'ImageWithCanvas__imgContainer--hidden': isLoading,
	})
	const imageCSS = cx({
		ImageWithCanvas__image: !alignHeight,
		'ImageWithCanvas__image--height': alignHeight,
		'ImageWithCanvas__image--hidden': isLoading,
	})

	useEffect(() => {
		const imageContainerDiv = imageContainerRef.current
		imageContainerDiv.addEventListener('wheel', handleWheel, { passive: false })

		return () => imageContainerDiv.removeEventListener('wheel', handleWheel, { passive: false })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleUserKeyPress = useCallback(
		event => {
			const { key } = event
			if (selectedAnnotationId && (event.ctrlKey || event.metaKey) && (key === 'Backspace' || key === 'Delete')) {
				deleteAnnotation({}, selectedAnnotationId)
			}
		},
		[selectedAnnotationId],
	)

	useEffect(() => {
		window.addEventListener('keydown', handleUserKeyPress)

		return () => {
			window.removeEventListener('keydown', handleUserKeyPress)
		}
	}, [handleUserKeyPress])

	useEffect(() => {
		if (isAnnotationImageLoaded && isOriginalImageLoaded) {
			if (imageRef.current?.clientWidth && imageRef.current?.clientHeight) {
				const imageWidth = imageRef.current.clientWidth
				const imageHeight = imageRef.current.clientHeight

				updateCanvas(imageWidth, imageHeight)
				updateAnnotations()
				setImageDimension({ width: imageWidth, height: imageHeight })
			}
		}
	}, [isAnnotationImageLoaded, isOriginalImageLoaded, alignHeight, props.isNavigationOpened])

	const updateCanvas = (width = 500, height = 500) => {
		const canvas = canvasRef.current
		canvas.width = width
		canvas.height = height

		const context = canvas.getContext('2d')
		context.lineCap = 'round'
		context.strokeStyle = 'black'
		context.lineWidth = 5
		contextRef.current = context

		const canvasOffSet = canvas.getBoundingClientRect()
		canvasOffSetX.current = canvasOffSet.left
		canvasOffSetY.current = canvasOffSet.top
	}

	const onOriginalImageLoaded = () => {
		setIsOriginalImageLoaded(true)
	}

	const onImageLoaded = e => {
		let naturalHeight = e.target.naturalHeight
		let naturalWidth = e.target.naturalWidth

		const imgProportion = naturalHeight / naturalWidth

		const heightForImage = window.innerHeight - FIXED_AREA

		const leftPadding = NAVIGATION_WIDTH + CONTENT_PADDING
		const rightPadding = CONTENT_PADDING
		const gapBetweenImages = 40

		const widthForImage = (window.innerWidth - (leftPadding + rightPadding + gapBetweenImages)) / 2

		if (widthForImage < naturalWidth) {
			const difference = naturalWidth - widthForImage
			naturalWidth -= difference
		} else {
			const difference = widthForImage - naturalWidth
			naturalWidth += difference
		}

		naturalHeight = naturalWidth * imgProportion

		if (heightForImage <= naturalHeight) {
			setAlignHeight(true)
			// after changing alignHeight useEffect will be trigered and there we'll update setImageDimension in order to first plot is successful
		} else {
			setAlignHeight(false)
		}

		if (imageRef.current?.clientWidth && imageRef.current?.clientHeight) {
			const imageWidth = imageRef.current.clientWidth
			const imageHeight = imageRef.current.clientHeight

			updateCanvas(imageWidth, imageHeight)
			updateAnnotations()
		}

		setIsAnnotationImageLoaded(true)
	}

	const startDrawingRectangle = ({ nativeEvent }) => {
		nativeEvent.preventDefault()
		nativeEvent.stopPropagation()
		setSelectedAnnotationId(0)

		startX.current = nativeEvent.clientX - canvasOffSetX.current
		startY.current = nativeEvent.clientY - canvasOffSetY.current

		setIsDrawing(true)
	}

	const drawRectangle = ({ nativeEvent }) => {
		if (!isDrawing) {
			return
		}

		nativeEvent.preventDefault()
		nativeEvent.stopPropagation()

		const newMouseX = nativeEvent.clientX - canvasOffSetX.current
		const newMouseY = nativeEvent.clientY - canvasOffSetY.current

		const rectWidht = newMouseX - startX.current
		const rectHeight = newMouseY - startY.current

		contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)

		contextRef.current.fillRect(startX.current, startY.current, rectWidht, rectHeight) // strokeRect for line

		setMarkedWidth(rectWidht)
		setMarkedHeight(rectHeight)

		contextRef.current.fillStyle = 'rgba(225,225,225,0.5)'
	}

	const onMouseUp = ({ nativeEvent }) => {
		// we always want to save top left corner
		const topLeftX = Math.min(startX.current, nativeEvent.clientX - canvasOffSetX.current)
		const topLeftY = Math.min(startY.current, nativeEvent.clientY - canvasOffSetY.current)

		setIsDrawing(false)
		submitData(topLeftX, topLeftY)
	}

	const stopDrawingRectangle = () => {
		setIsDrawing(false)
	}

	const submitData = async (topLeftX, topLeftY) => {
		const imageWidth = imageRef.current.clientWidth
		const imageHeight = imageRef.current.clientHeight

		const scaledImageWidth = imageWidth * scale.current
		const scaledImageHeight = imageHeight * scale.current

		const { sideWidthOffset, sideHeightOffset } = getSideOffsets(scale.current)
		const leftOffset = sideWidthOffset - translateX.current
		const topOffset = sideHeightOffset - translateY.current

		let selectedX = topLeftX + leftOffset
		let selectedY = topLeftY + topOffset

		const dataForSending = {
			imageId: props.id,
			imageWidth: parseInt(scaledImageWidth),
			imageHeight: parseInt(scaledImageHeight),
			selectedX: parseInt(selectedX),
			selectedY: parseInt(selectedY),
			areaWidth: parseInt(Math.abs(markedWidth)),
			areaHeight: parseInt(Math.abs(markedHeight)),
			type: props.selectedType.value,
		}

		if (props.submitData) {
			const res = await props.submitData(dataForSending)
			if (res?.data?.insertedId) {
				contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
			}
		}
	}

	const updateSingleArea = async (areaId, topLeftX, topLeftY, width, height) => {
		const imageWidth = imageRef.current.clientWidth
		const imageHeight = imageRef.current.clientHeight

		setSelectedAnnotationId(areaId)

		const scaledImageWidth = imageWidth * scale.current
		const scaledImageHeight = imageHeight * scale.current

		const { sideWidthOffset, sideHeightOffset } = getSideOffsets(scale.current)
		const leftOffset = sideWidthOffset - translateX.current
		const topOffset = sideHeightOffset - translateY.current

		let selectedX = topLeftX + leftOffset
		let selectedY = topLeftY + topOffset

		const dataForSending = {
			areaId,
			imageId: props.id,
			imageWidth: parseInt(scaledImageWidth),
			imageHeight: parseInt(scaledImageHeight),
			selectedX: parseInt(selectedX),
			selectedY: parseInt(selectedY),
			areaWidth: parseInt(Math.abs(width)),
			areaHeight: parseInt(Math.abs(height)),
		}

		if (props.updateArea) {
			await props.updateArea(dataForSending)
		}
	}

	const deleteAnnotation = async (e, areaId) => {
		if (e?.stopPropagation) {
			e.stopPropagation()
		}

		setSelectedAnnotationId(0)

		const dataForSending = {
			areaId,
			imageId: props.id,
		}

		if (props.deleteArea) {
			await props.deleteArea(dataForSending)
		}
	}

	const onCopyToClipboard = () => {
		navigator.clipboard.writeText(props.url)
	}

	const onCopyToClipboardOriginal = () => {
		navigator.clipboard.writeText(props.originalUrl)
	}

	// moving

	const onMoveUp = () => {
		translateY.current = translateY.current + MOVING_STEP
		constraintMovingOutOfTheBox()
		setTransform()
	}

	const onMoveDown = () => {
		translateY.current = translateY.current - MOVING_STEP
		constraintMovingOutOfTheBox()
		setTransform()
	}

	const onMoveLeft = () => {
		translateX.current = translateX.current + MOVING_STEP
		constraintMovingOutOfTheBox()
		setTransform()
	}

	const onMoveRight = () => {
		translateX.current = translateX.current - MOVING_STEP
		constraintMovingOutOfTheBox()
		setTransform()
	}

	// zooming

	const onZoomOut = () => {
		scale.current = scale.current - ZOOMIN_STEP
		if (scale.current < 1) {
			scale.current = 1
		}

		constraintMovingOutOfTheBox()
		setTransform()
	}

	const onZoomIn = () => {
		scale.current = scale.current + ZOOMIN_STEP
		setTransform()
	}

	const handleWheel = event => {
		event.preventDefault()

		const imageContainerDiv = imageContainerRef.current
		imageContainerDiv.removeEventListener('wheel', handleWheel, { passive: false })

		const rectImage = imageRef.current.getBoundingClientRect()
		const xImage = (event.clientX - rectImage.x) / scale.current
		const yImage = (event.clientY - rectImage.y) / scale.current

		let delta = event.wheelDelta ? event.wheelDelta : -event.deltaY
		if (delta > 0) {
			scale.current = scale.current + 0.2
		} else {
			scale.current = scale.current - 0.2
		}

		if (scale.current < 1) {
			scale.current = 1
		}

		let m = delta > 0 ? 0.1 : -0.1
		translateX.current = translateX.current + -xImage * m * 2 + imageRef.current.offsetWidth * m
		translateY.current = translateY.current + -yImage * m * 2 + imageRef.current.offsetHeight * m

		if (delta <= 0) {
			constraintMovingOutOfTheBox()
		}

		setTransform()
		imageContainerDiv.addEventListener('wheel', handleWheel, { passive: false })
	}

	const getSideOffsets = imageScale => {
		const imageWidth = imageRef.current?.clientWidth || 0
		const imageHeight = imageRef.current?.clientHeight || 0

		const scaledImageWidth = imageWidth * imageScale
		const scaledImageHeight = imageHeight * imageScale

		const sideWidthOffset = (scaledImageWidth - imageWidth) / 2
		const sideHeightOffset = (scaledImageHeight - imageHeight) / 2

		return {
			sideWidthOffset: sideWidthOffset - MOVING_STEP,
			sideHeightOffset: sideHeightOffset - MOVING_STEP,
		}
	}

	const constraintMovingOutOfTheBox = () => {
		const { sideWidthOffset, sideHeightOffset } = getSideOffsets(scale.current)

		// x axis update if outzooming causes out of bounds
		const sideWidthMax = roundUpToMultiple(sideWidthOffset, MOVING_STEP)
		if (translateX.current < 0 && -translateX.current > sideWidthMax) {
			translateX.current = -sideWidthMax
		}

		if (translateX.current > 0 && translateX.current > sideWidthMax) {
			translateX.current = sideWidthMax
		}

		// y axis update if outzooming causes out of bounds
		const sideHeightMax = roundUpToMultiple(sideHeightOffset, MOVING_STEP)
		if (translateY.current < 0 && -translateY.current > sideHeightMax) {
			translateY.current = -sideHeightMax
		}

		if (translateY.current > 0 && translateY.current > sideHeightMax) {
			translateY.current = sideHeightMax
		}
	}

	const setTransform = () => {
		const steps = `translate(${translateX.current}px,${translateY.current}px) scale(${scale.current}) translate3d(0,0,0)`
		imageRef.current.style.transform = steps
		originalImageRef.current.style.transform = steps
		updateAnnotations()
	}

	const updateAnnotations = () => {
		const { sideWidthOffset, sideHeightOffset } = getSideOffsets(scale.current)

		const newImageWidth = imageRef.current.width * scale.current
		const newImageHeight = imageRef.current.height * scale.current

		if (annotationsRef.current?.length > 0) {
			for (const annotationRef of annotationsRef.current) {
				const id = annotationRef?.props?.id
				const existingAnnData = savedAnnotationsRef.current[id]
				if (existingAnnData?.id) {
					const x = existingAnnData.xRatio * newImageWidth - (sideWidthOffset - translateX.current)
					const y = existingAnnData.yRatio * newImageHeight - (sideHeightOffset - translateY.current)
					const width = existingAnnData.widthRatio * newImageWidth
					const height = existingAnnData.heightRatio * newImageHeight

					annotationRef.updateSize({ width, height })
					annotationRef.updatePosition({ x, y })
				}
			}
		}
	}

	useEffect(() => {
		if (props.selectedAreas?.length > 0 && isAnnotationImageLoaded) {
			populateAnnotations()
		}
	}, [isAnnotationImageLoaded, props.selectedAreas])

	const populateAnnotations = () => {
		const savedAnnotations = props.selectedAreas.reduce((acc, curr) => {
			const id = curr.area_id
			const xRatio = curr.selected_x / curr.image_width
			const yRatio = curr.selected_y / curr.image_height
			const widthRatio = curr.area_width / curr.image_width
			const heightRatio = curr.area_heigh / curr.image_height

			return {
				...acc,
				[id]: {
					id,
					xRatio,
					yRatio,
					widthRatio,
					heightRatio,
				},
			}
		}, {})

		savedAnnotationsRef.current = savedAnnotations
	}

	const handleTouchStart = event => {
		event.preventDefault()
	}

	const handleTouchMove = event => {
		if (event.touches.length === 2) {
			const touch1 = event.touches[0]
			const touch2 = event.touches[1]

			const distance = Math.sqrt(Math.pow(touch2.pageX - touch1.pageX, 2) + Math.pow(touch2.pageY - touch1.pageY, 2))

			const zoomFactor = distance / 100
		}
	}

	const onDragStop = (id, event, delta) => {
		const x = delta.x
		const y = delta.y

		const newImageWidth = imageRef.current.width * scale.current
		const newImageHeight = imageRef.current.height * scale.current
		const existingAnnData = savedAnnotationsRef.current[id]
		if (existingAnnData?.id) {
			const width = existingAnnData.widthRatio * newImageWidth
			const height = existingAnnData.heightRatio * newImageHeight

			updateSingleArea(id, x, y, width, height)
		}
	}

	const onResizeStop = (id, e, direction, ref, delta, position) => {
		const x = position.x
		const y = position.y

		const width = parseInt(ref.style.width)
		const height = parseInt(ref.style.height)

		updateSingleArea(id, x, y, width, height)
	}

	let annotations = []

	if (props.selectedAreas?.length > 0 && imageDimension.width) {
		const { sideWidthOffset, sideHeightOffset } = getSideOffsets(scale.current)

		annotations = props.selectedAreas?.map(area => {
			const imageWidth = imageDimension.width * scale.current
			const imageHeight = imageDimension.height * scale.current

			const xRatio = area.selected_x / area.image_width
			const yRatio = area.selected_y / area.image_height

			const widthRatio = area.area_width / area.image_width
			const heightRatio = area.area_heigh / area.image_height

			const x = Math.round(xRatio * imageWidth) - (sideWidthOffset - translateX.current)
			const y = Math.round(yRatio * imageHeight) - (sideHeightOffset - translateY.current)
			const width = Math.round(widthRatio * imageWidth)
			const height = Math.round(heightRatio * imageHeight)

			return {
				id: area.area_id,
				x,
				y,
				width,
				height,
				type: area.type,
			}
		})
	}

	return (
		<div className='ImageWithCanvas'>
			<div className={loadingCSS}>Loading...</div>
			<div className={imageSideBySideWrapperCSS}>
				<div
					ref={imageContainerRef}
					className={imageContainerCSS}
					// onWheel={handleWheel}
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}>
					<div className='ImageWithCanvas__imgWrapper'>
						<img ref={imageRef} onLoad={onImageLoaded} className={imageCSS} src={props.url} alt='bike drive'></img>

						<div className='ImageWithCanvas__butttons'>
							<div className='ImageWithCanvas__butttonsForMoving'>
								<button
									className='ImageWithCanvas__butttonsArrow ImageWithCanvas__butttonsArrow--fullScreen'
									onClick={props.onFullScreen}>
									<img src='/full-screen.png' alt='Small' />
								</button>
								<button
									className='ImageWithCanvas__butttonsArrow ImageWithCanvas__butttonsArrow--clipboard'
									onClick={onCopyToClipboard}>
									<img src='/copy-clipboard.png' alt='Small' />
								</button>
								<button className='ImageWithCanvas__butttonsArrow' onClick={onMoveLeft}>
									&larr;
								</button>
								<button className='ImageWithCanvas__butttonsArrow' onClick={onMoveRight}>
									&rarr;
								</button>
								<button className='ImageWithCanvas__butttonsArrow' onClick={onMoveUp}>
									&uarr;
								</button>
								<button className='ImageWithCanvas__butttonsArrow' onClick={onMoveDown}>
									&darr;
								</button>
							</div>
							<button onClick={onZoomOut} className='ImageWithCanvas__butttonZoom'>
								-
							</button>
							<button onClick={onZoomIn} className='ImageWithCanvas__butttonZoom'>
								+
							</button>
						</div>
					</div>
					{props.locked && <div className='ImageWithCanvas__lockedWrapper' />}
					<canvas
						className='canvas-container-rect'
						ref={canvasRef}
						onMouseDown={startDrawingRectangle}
						onMouseMove={drawRectangle}
						onMouseUp={onMouseUp}
						onMouseLeave={stopDrawingRectangle}
					/>
					{annotations.map((annotation, index) => {
						return (
							<Rnd
								key={annotation.id}
								id={annotation.id}
								ref={el => (annotationsRef.current[index] = el)}
								className={cx(
									'ImageWithCanvas__singleAnnotation',
									`AnnotationLabelRactangle--${annotation.type}`,
									{
										'ImageWithCanvas__singleAnnotation--selected': annotation.id === selectedAnnotationId,
									},
								)}
								lockAspectRatio={false}
								enableUserSelectHack={true}
								default={{
									x: annotation.x,
									y: annotation.y,
									width: annotation.width,
									height: annotation.height,
								}}
								onDragStop={onDragStop.bind(null, annotation.id)}
								onResizeStop={onResizeStop.bind(null, annotation.id)}
								bounds='parent'>
								<span
									className={cx(
										'ImageWithCanvas__singleAnnotationName',
										`AnnotationLabelText--${annotation.type}`,
									)}
									style={{ width: annotation.width }}>
									{getAnnotationNameFormType(annotation.type)}
								</span>
								<span
									className={cx('ImageWithCanvas__closeAnnotation')}
									onClick={e => deleteAnnotation(e, annotation.id)}>
									&#215;
								</span>
							</Rnd>
						)
					})}
				</div>
				<div className='ImageWithCanvas__imgContainer'>
					<img
						ref={originalImageRef}
						className={imageCSS}
						src={props.originalUrl}
						onLoad={onOriginalImageLoaded}
						alt='bike drive'></img>
					<div className='ImageWithCanvas__butttons ImageWithCanvas__butttons--onOriginalImage'>
						<div className='ImageWithCanvas__butttonsForMoving'>
							<button
								className='ImageWithCanvas__butttonsArrow ImageWithCanvas__butttonsArrow--clipboard'
								onClick={onCopyToClipboardOriginal}>
								<img src='/copy-clipboard.png' alt='Small' />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ImageWithCanvas
