import React, { useState, useEffect, useRef } from 'react'
import ImageWithCanvas from '../ImageWithCanvas'
import {
	getAllAppImages,
	addImageSelectedArea,
	updateImageSelectedArea,
	deleteImageSelectedArea,
	updateImageStatus,
} from '../../api'

import {
	ANNOTATION_TYPE_FACE,
	ANNOTATION_TYPE_LICENSE_PLATE,
	ANNOTATION_TYPE_BACKGROUND,
	IMAGE_STATUS_LOCKED,
	IMAGE_STATUS_UNLOCKED,
	IMAGES_FILTER_ALL,
	IMAGES_FILTER_LABELED,
	IMAGES_FILTER_UNLABELED,
	sortArray,
} from '../../utils'

import './styles.scss'
import cx from 'classnames'

const MAX_PRELOADED_IMAGES_ON_SIDE = 2

const ImageSlider = ({ selectedType, onUpdateImages, filter, isNavigationOpened, selectedRoute, setAllImages, ...props }) => {
	const [images, setImages] = useState([])
	const [filteredImages, setFilteredImages] = useState([])
	const [visibleImages, setVisibleImages] = useState([])
	const [faceImagesCount, setFaceImagesCount] = useState(0)
	const [licensePlateImagesCount, setLicensePlateImagesCount] = useState(0)
	const [backgroundImagesCount, setBackgroundImagesCount] = useState(0)
	const [currentImageIndex, setCurrentImageIndex] = useState(0)
	const [isFullScreen, setIsFullScreen] = useState(false)

	useEffect(() => {
		setCurrentImageIndex(0)
	}, [filter])

	useEffect(() => {
		setCurrentImageIndex(0)
	}, [selectedRoute])

	const imageRef = useRef(null)
	const [closeFullButtonPosition, setCloseFullButtonPosition] = useState({ top: 0, right: 0 })

	useEffect(() => {
		if (isFullScreen) {
			const handleResize = () => {
				if (imageRef.current) {
					const image = imageRef.current
					const containerWidth = image.parentNode.clientWidth
					const containerHeight = image.parentNode.clientHeight
					const imageRatio = image.naturalWidth / image.naturalHeight
					const containerRatio = containerWidth / containerHeight

					let scaledWidth, scaledHeight
					if (imageRatio < containerRatio) {
						scaledWidth = containerHeight * imageRatio
						scaledHeight = containerHeight
					} else {
						scaledWidth = containerWidth
						scaledHeight = containerWidth / imageRatio
					}

					let right = 0
					let top = 0
					if (scaledWidth < containerWidth) {
						// aligned by height
						right = (containerWidth - scaledWidth) / 2
					}

					if (scaledHeight < containerHeight) {
						// aligned by width
						top = (containerHeight - scaledHeight) / 2
					}

					setCloseFullButtonPosition({ top, right })
				}
			}

			handleResize() // Initial positioning
			window.addEventListener('resize', handleResize)
			return () => window.removeEventListener('resize', handleResize)
		}
	}, [isFullScreen])

	useEffect(() => {
		fetchData()
	}, [])

	useEffect(() => {
		if (selectedRoute?.routeId) {
			const filteredImages = images.filter(img => img.routeId === selectedRoute?.routeId)
			setFilteredImages(filteredImages)
		} else {
			setFilteredImages(images)
		}
	}, [selectedRoute])

	useEffect(() => {
		updateVisibleImages()
	}, [filteredImages])

	useEffect(() => {
		if (props.setImages) {
			props.setImages(visibleImages)
		}
	}, [visibleImages])

	useEffect(() => {
		if (props.setCurrentImageIndex) {
			props.setCurrentImageIndex(currentImageIndex)
		}
	}, [currentImageIndex])

	useEffect(() => {
		if (props.selectedImageIndex >= 0 && props.selectedImageIndex < visibleImages.length) {
			setCurrentImageIndex(props.selectedImageIndex)
		}
	}, [props.selectedImageIndex])

	useEffect(() => {
		onUpdateImages(filteredImages)
		updateVisibleImages()
	}, [filteredImages])

	useEffect(() => {
		updateVisibleImages()
	}, [filter])

	const updateVisibleImages = () => {
		let availableStatuses = []
		if (filter === IMAGES_FILTER_ALL || filter === IMAGES_FILTER_LABELED) {
			availableStatuses.push(IMAGE_STATUS_LOCKED)
		}

		if (filter === IMAGES_FILTER_ALL || filter === IMAGES_FILTER_UNLABELED) {
			availableStatuses.push(IMAGE_STATUS_UNLOCKED)
		}

		const visibleImages = filteredImages.filter(i => availableStatuses.includes(i.status))
		setVisibleImages(visibleImages)
	}

	useEffect(() => {
		const faceImagesCount = images.reduce((acc, curr) => {
			if (curr.selectedAreas?.length > 0) {
				const currentFaceCount = curr.selectedAreas.filter(area => area.type === ANNOTATION_TYPE_FACE).length

				return acc + currentFaceCount
			}

			return acc
		}, 0)

		setFaceImagesCount(faceImagesCount)

		const licensePlateImagesCount = images.reduce((acc, curr) => {
			if (curr.selectedAreas?.length > 0) {
				const currentFaceCount = curr.selectedAreas.filter(area => area.type === ANNOTATION_TYPE_LICENSE_PLATE).length

				return acc + currentFaceCount
			}

			return acc
		}, 0)

		setLicensePlateImagesCount(licensePlateImagesCount)

		const backgroundImagesCount = images.reduce((acc, curr) => {
			if (curr.selectedAreas?.length > 0) {
				const currentFaceCount = curr.selectedAreas.filter(area => area.type === ANNOTATION_TYPE_BACKGROUND).length

				return acc + currentFaceCount
			}

			return acc
		}, 0)

		setBackgroundImagesCount(backgroundImagesCount)
	}, [images])

	const fetchData = async () => {
		const res = await getAllAppImages()

		if (res?.data?.images?.length > 0) {
			const images = res.data.images.map(i => ({
				id: i.id,
				url: i.anonymized_aws_url,
				originalUrl: i.aws_url,
				dateTaken: i.date_time_taken,
				sessionName: i.session_name,
				selectedAreas: i.selectedAreas,
				status: i.status,
				routeId: i.routeId,
				routeName: i.routeName,
				routeNumber: i.routeNumber,
				routeDirection: i.routeDirection,
			}))

			sortArray(images, 'dateTaken')
			setImages(images)
			setAllImages(images)
			setVisibleImages(images)
			setFilteredImages(images)
		}
	}

	const submitData = async dataForSending => {
		const res = await addImageSelectedArea(dataForSending)
		if (res?.data?.insertedId) {
			const id = dataForSending.imageId

			const newArea = {
				area_heigh: dataForSending.areaHeight,
				area_id: res.data.insertedId,
				area_width: dataForSending.areaWidth,
				image_height: dataForSending.imageHeight,
				image_width: dataForSending.imageWidth,
				selected_x: dataForSending.selectedX,
				selected_y: dataForSending.selectedY,
				type: dataForSending.type,
			}

			const newImages = images.map(image => {
				if (image.id === id) {
					return {
						...image,
						selectedAreas: [...image.selectedAreas, newArea],
					}
				}

				return image
			})

			const newfFlteredImages = filteredImages.map(image => {
				if (image.id === id) {
					return {
						...image,
						selectedAreas: [...image.selectedAreas, newArea],
					}
				}

				return image
			})

			const newVisibleImages = visibleImages.map(image => {
				if (image.id === id) {
					return {
						...image,
						selectedAreas: [...image.selectedAreas, newArea],
					}
				}

				return image
			})

			setImages(newImages)
			setFilteredImages(newfFlteredImages)
			setVisibleImages(newVisibleImages)
		}

		return res
	}

	const updateArea = async dataForSending => {
		const res = await updateImageSelectedArea(dataForSending)
		if (res?.status === 200) {
			const imageId = dataForSending.imageId
			const areaId = dataForSending.areaId
			const newArea = {
				area_heigh: dataForSending.areaHeight,
				area_id: areaId,
				area_width: dataForSending.areaWidth,
				image_height: dataForSending.imageHeight,
				image_width: dataForSending.imageWidth,
				selected_x: dataForSending.selectedX,
				selected_y: dataForSending.selectedY,
			}

			const newImages = images.map(image => {
				if (image.id === imageId) {
					const newSelectedAreas = image.selectedAreas.map(area => {
						if (area.area_id === areaId) {
							return { ...newArea, type: area.type }
						}

						return area
					})

					return {
						...image,
						selectedAreas: newSelectedAreas,
					}
				}

				return image
			})

			const newFilteredImages = filteredImages.map(image => {
				if (image.id === imageId) {
					const newSelectedAreas = image.selectedAreas.map(area => {
						if (area.area_id === areaId) {
							return { ...newArea, type: area.type }
						}

						return area
					})

					return {
						...image,
						selectedAreas: newSelectedAreas,
					}
				}

				return image
			})

			const newVisibleImages = visibleImages.map(image => {
				if (image.id === imageId) {
					const newSelectedAreas = image.selectedAreas.map(area => {
						if (area.area_id === areaId) {
							return { ...newArea, type: area.type }
						}

						return area
					})

					return {
						...image,
						selectedAreas: newSelectedAreas,
					}
				}

				return image
			})

			setImages(newImages)
			setFilteredImages(newFilteredImages)
			setVisibleImages(newVisibleImages)
		}

		return res
	}

	const deleteArea = async dataForSending => {
		const res = await deleteImageSelectedArea(dataForSending)

		if (res?.status === 200) {
			const imageId = dataForSending.imageId
			const areaId = dataForSending.areaId

			const newImages = images.map(image => {
				if (image.id === imageId) {
					const newSelectedAreas = image.selectedAreas.filter(area => area.area_id !== areaId)

					return {
						...image,
						selectedAreas: newSelectedAreas,
					}
				}

				return image
			})

			const newFilteredImages = filteredImages.map(image => {
				if (image.id === imageId) {
					const newSelectedAreas = image.selectedAreas.filter(area => area.area_id !== areaId)

					return {
						...image,
						selectedAreas: newSelectedAreas,
					}
				}

				return image
			})

			const newVisibleImages = visibleImages.map(image => {
				if (image.id === imageId) {
					const newSelectedAreas = image.selectedAreas.filter(area => area.area_id !== areaId)

					return {
						...image,
						selectedAreas: newSelectedAreas,
					}
				}

				return image
			})

			setImages(newImages)
			setFilteredImages(newFilteredImages)
			setVisibleImages(newVisibleImages)
		}
	}

	const onPrev = () => {
		if (currentImageIndex) {
			setCurrentImageIndex(currentImageIndex - 1)
		}
	}

	const onNext = () => {
		if (currentImageIndex < images.length - 1) {
			setCurrentImageIndex(currentImageIndex + 1)
		}
	}

	const toggleStatus = async () => {
		const payload = {}

		if (totalImages > 0) {
			payload.imageId = visibleImages[currentImageIndex].id
			if (visibleImages[currentImageIndex].status === IMAGE_STATUS_LOCKED) {
				payload.status = IMAGE_STATUS_UNLOCKED
			} else {
				payload.status = IMAGE_STATUS_LOCKED
			}
		}

		const res = await updateImageStatus(payload)
		if (res?.status === 200) {
			const newImages = images.map(image => {
				if (image.id === payload.imageId) {
					return {
						...image,
						status: payload.status,
					}
				}

				return image
			})

			const newFilteredImages = filteredImages.map(image => {
				if (image.id === payload.imageId) {
					return {
						...image,
						status: payload.status,
					}
				}

				return image
			})
			const newVisibleImages = visibleImages.map(image => {
				if (image.id === payload.imageId) {
					return {
						...image,
						status: payload.status,
					}
				}

				return image
			})

			setImages(newImages)
			setFilteredImages(newFilteredImages)
			setVisibleImages(newVisibleImages)
		}
	}

	const onFullScreen = () => {
		setIsFullScreen(true)
		props.setAutoCloseNavigation(props.autoCloseNavigation + 1)
	}

	const onCloseFullScreen = () => {
		setIsFullScreen(false)
		props.setAutoShowNavigation(props.autoShowNavigation + 1)
	}

	const totalImages = images.length

	let statusButton = ''
	if (visibleImages.length > 0) {
		if (visibleImages[currentImageIndex].status === IMAGE_STATUS_LOCKED) {
			statusButton = 'Unlock'
		} else {
			statusButton = 'Labeled'
		}
	}

	return (
		<div className='ImageSliderContainer'>
			{!isFullScreen && (
				<>
					<div className='ImageSlider__header'>
						<div className='ImageSlider__labelContainer'>
							<div>Active Label:</div>
							<div
								className={cx('ImageSlider__labelRactangle', `AnnotationLabelRactangle--${selectedType.value}`)}
							/>
							<div>{selectedType.name}</div>
						</div>
						<div className='ImageSlider__headerInfo'>
							<div>Total images: {totalImages}</div>
							<div>Annotated faces: {faceImagesCount}</div>
							<div>License plates: {licensePlateImagesCount}</div>
							<div>Backgrounds: {backgroundImagesCount}</div>
						</div>
					</div>
					{visibleImages?.length > 0 && (
						<div className='ImageSlider'>
							{visibleImages.map((image, index) => {
								const currentOffest = Math.abs(index - currentImageIndex)
								if (currentOffest > MAX_PRELOADED_IMAGES_ON_SIDE) {
									return <div key={`image-${image.id}`} />
								}

								const imageCSS = cx('ImageSlider__singleImage', {
									'ImageSlider__singleImage--hidden': index !== currentImageIndex,
								})

								return (
									<div key={`image-${image.id}`} className={imageCSS}>
										<ImageWithCanvas
											id={image.id}
											url={image.url}
											originalUrl={image.originalUrl}
											selectedAreas={image.selectedAreas}
											submitData={submitData}
											updateArea={updateArea}
											deleteArea={deleteArea}
											selectedType={selectedType}
											locked={image.status === IMAGE_STATUS_LOCKED}
											isNavigationOpened={isNavigationOpened}
											onFullScreen={onFullScreen}
										/>
									</div>
								)
							})}
							{/* <ImageWithCanvas id={images[currentImageIndex].id} url={images[currentImageIndex].url} /> */}
						</div>
					)}
				</>
			)}
			{isFullScreen && (
				<div className='ImageSlider__fullContainer'>
					{visibleImages.map((image, index) => {
						const currentOffest = Math.abs(index - currentImageIndex)
						if (currentOffest > MAX_PRELOADED_IMAGES_ON_SIDE) {
							return <div key={`image-${image.id}`} />
						}

						const imageCSS = cx('ImageSlider__fullSingleImage', {
							'ImageSlider__fullSingleImage--hidden': index !== currentImageIndex,
						})

						return (
							<React.Fragment key={`image-${image.id}`}>
								<div className={imageCSS}>
									<img ref={imageRef} src={image.url} alt='bike drive' />
								</div>
								<div
									className='ImageSlider__fullImageButton'
									style={closeFullButtonPosition}
									onClick={onCloseFullScreen}>
									<img src='/full-screen.png' alt='Small' />
								</div>
							</React.Fragment>
						)
					})}
					{/* <ImageWithCanvas id={images[currentImageIndex].id} url={images[currentImageIndex].url} /> */}
				</div>
			)}
			<div className='ImageSlider__buttons'>
				<button disabled={currentImageIndex === 0} onClick={onPrev}>
					Previous
				</button>
				<button onClick={toggleStatus}>{statusButton}</button>
				<button disabled={currentImageIndex === visibleImages.length - 1} onClick={onNext}>
					Next
				</button>
			</div>
		</div>
	)
}

export default ImageSlider
