import React, { useState, useCallback, useEffect, useRef } from 'react'
import { ANNOTATION_TYPES, IMAGES_FILTERS } from '../../utils'

import './styles.scss'
import cx from 'classnames'

const LeftNavigation = ({ selectedType, onTypeChanged, filter, setFilter, counts, images, allImages, ...props }) => {
	const [isOpen, setIsOpen] = useState(true)
	const [hoveredFileId, setHoveredFileId] = useState(0)
	const annotationTypesValues = Object.values(ANNOTATION_TYPES)
	const filesTableRef = useRef()
	const clickHappenedRef = useRef()

	const [availableRoutes, setAvailableRoutes] = useState([])
	const [selectedRoute, setSelectedRoute] = useState({})

	useEffect(() => {
		if (props.autoCloseNavigation) {
			setIsOpen(false)
			if (props.setIsOpen) {
				props.setIsOpen(false)
			}
		}
	}, [props.autoCloseNavigation])

	useEffect(() => {
		if (props.autoShowNavigation) {
			setIsOpen(true)
			if (props.setIsOpen) {
				props.setIsOpen(true)
			}
		}
	}, [props.autoShowNavigation])

	useEffect(() => {
		if (allImages.length) {
			const availableRoutes = allImages.reduce((acc, curr) => {
				const routeId = curr.routeId
				if (!routeId || acc[routeId]) {
					return acc
				}

				return {
					...acc,
					[routeId]: {
						routeId,
						routeName: curr.routeName,
						routeNumber: curr.routeNumber,
						routeDirection: curr.routeDirection,
					},
				}
			}, {})

			setAvailableRoutes(Object.values(availableRoutes))
		}
	}, [allImages])

	useEffect(() => {
		if (!filesTableRef.current || clickHappenedRef.current) {
			return
		}

		const selectedItems = document.getElementsByClassName('LeftNavigation__tableCell--selected')
		if (selectedItems.length > 0) {
			const selectedItemRect = selectedItems[0].getBoundingClientRect()
			const listRect = filesTableRef.current.getBoundingClientRect()

			if (selectedItemRect.bottom > listRect.bottom) {
				filesTableRef.current.scrollTo({
					top: filesTableRef.current.scrollTop + (selectedItemRect.bottom - listRect.bottom) + 12,
					behavior: 'smooth',
				})
			} else if (selectedItemRect.top < listRect.top) {
				if (props.currentImageIndex === 0) {
					filesTableRef.current.scrollTo({
						top: 0,
						behavior: 'smooth',
					})
				} else {
					filesTableRef.current.scrollTo({
						top: filesTableRef.current.scrollTop - (listRect.top - selectedItemRect.top) - 12,
						behavior: 'smooth',
					})
				}
			}
		}
	}, [props.currentImageIndex])

	const onRouteSelected = event => {
		const routeId = +event.target.value
		const route = availableRoutes.find(r => r.routeId === routeId)

		setSelectedRoute(route || {})
		if (props.setSelectedRoute) {
			props.setSelectedRoute(route || {})
		}
	}

	const handleUserKeyPress = useCallback(
		event => {
			const { key } = event
			if (key.toLowerCase() === 'l') {
				event.preventDefault()
			}

			if (key.toLowerCase() === 'l' && (event.ctrlKey || event.metaKey)) {
				let nextIndex = 0
				for (let i = 0; i < annotationTypesValues.length; i++) {
					if (annotationTypesValues[i].value === selectedType.value) {
						nextIndex = i + 1
					}
				}

				if (nextIndex === annotationTypesValues.length) {
					nextIndex = 0
				}

				const nextType = annotationTypesValues[nextIndex]

				onTypeChanged(nextType)
			}
		},
		[selectedType, annotationTypesValues],
	)

	useEffect(() => {
		window.addEventListener('keydown', handleUserKeyPress)

		return () => {
			window.removeEventListener('keydown', handleUserKeyPress)
		}
	}, [handleUserKeyPress])

	const toggleNavigation = () => {
		setIsOpen(!isOpen)
		if (props.setIsOpen) {
			props.setIsOpen(!isOpen)
		}
	}

	const onFileHovered = id => {
		setHoveredFileId(id)
	}

	const onFileSelected = index => {
		if (props.selectImage) {
			props.selectImage(index)
			clickHappenedRef.current = true
			setTimeout(() => {
				clickHappenedRef.current = false
			}, 500)
		}
	}

	const containerCSS = cx('LeftNavigation', { 'LeftNavigation--closed': !isOpen })
	const contentCSS = cx('LeftNavigation__content', { 'LeftNavigation__content--closed': !isOpen })
	const arrowCSS = cx('LeftNavigation__expandButton', { 'LeftNavigation__expandButton--closed': !isOpen })

	const firstPartCSS = cx('LeftNavigation__firstPart', { 'LeftNavigation__firstPart--hidden': !isOpen })

	return (
		<div className={containerCSS}>
			<div className={firstPartCSS}>
				<div className={arrowCSS} onClick={toggleNavigation}>
					<span>&larr;</span>
				</div>
				<div className={contentCSS}>
					<div className='LeftNavigation__title'>Labels</div>
					{annotationTypesValues.map(type => {
						const ractangleCSS = cx('LeftNavigation__labelRactangle', `AnnotationLabelRactangle--${type.value}`)

						return (
							<div
								key={type.value}
								className='LeftNavigation__singleLabel'
								onClick={onTypeChanged.bind(null, type)}>
								<div className={ractangleCSS} />
								<div className='LeftNavigation__labelName'>{type.name}</div>
							</div>
						)
					})}
					<div className='LeftNavigation__title'>Shortcuts</div>
					<div className='LeftNavigation__singleShortcut'>
						<div className='LeftNavigation__singleShortcutName'>Zoom</div>
						<div>mouse wheel</div>
					</div>
					<div className='LeftNavigation__singleShortcut'>
						<div className='LeftNavigation__singleShortcutName'>Switch label</div>
						<div>⌘ + L</div>
					</div>
					<div className='LeftNavigation__singleShortcut'>
						<div className='LeftNavigation__singleShortcutName'>Delete label</div>
						<div>⌘ + Delete</div>
					</div>
					<div className='LeftNavigation__title'>Filter</div>
					{IMAGES_FILTERS.map(currFilter => {
						let currentCount = ''
						if (counts[currFilter] || counts[currFilter] === 0) {
							currentCount = `(${counts[currFilter]})`
						}

						return (
							<div className='LeftNavigation__filterContainer' key={`filter-${currFilter}`}>
								<input
									type='radio'
									id={currFilter}
									name='img_filter'
									value={currFilter}
									checked={currFilter === filter}
									onChange={setFilter}
									disabled={!counts[currFilter]}
								/>
								<label htmlFor={currFilter}>{`${currFilter} ${currentCount}`}</label>
							</div>
						)
					})}
				</div>
				<div className='LeftNavigation__title'>Files</div>
				<div className='LeftNavigation__selectRoute'>
					<select value={selectedRoute?.routeId || ''} onChange={onRouteSelected}>
						<option value=''>Choose route</option>
						{availableRoutes.map(route => {
							return (
								<option key={`route-option-${route.routeId}`} value={route.routeId}>
									{route.routeName}
								</option>
							)
						})}
					</select>
				</div>
			</div>
			{images.length > 0 && (
				<div className='LeftNavigation__filesTableContainer' ref={filesTableRef}>
					<div className='LeftNavigation__filesTable'>
						<div className='LeftNavigation__filesTableHeader LeftNavigation__sessionNameCell'>Session</div>
						<div className='LeftNavigation__filesTableHeader LeftNavigation__fileNameCell'>File Name</div>
						<div className='LeftNavigation__filesTableHeader LeftNavigation__dateTakenCell'>Date Taken</div>
						{images.map((image, index) => {
							let dateTaken = '-'
							if (image.dateTaken?.length > 15) {
								const month = image.dateTaken.substring(5, 7)
								const day = image.dateTaken.substring(8, 10)
								const hours = image.dateTaken.substring(11, 13)
								const minutes = image.dateTaken.substring(14, 16)

								dateTaken = `${day}.${month}. at ${hours}:${minutes}`
							}

							const sessionName = image.sessionName
							const fileNamePosition = image.url?.indexOf('amazonaws.com')
							let fileName = ''
							if (fileNamePosition > 0) {
								fileName = image.url?.substring(fileNamePosition + 14)
							}

							const isHovered = hoveredFileId === image.id
							const isSelected = props.currentImageIndex === index
							const sessionNameCSS = cx('LeftNavigation__tableCell LeftNavigation__sessionNameCell', {
								'LeftNavigation__tableCell--hovered': isHovered,
								'LeftNavigation__tableCell--selected': isSelected,
							})
							const fileNameCSS = cx('LeftNavigation__tableCell LeftNavigation__fileNameCell', {
								'LeftNavigation__tableCell--hovered': isHovered,
								'LeftNavigation__tableCell--selected': isSelected,
							})
							const dateTakenCSS = cx('LeftNavigation__tableCell LeftNavigation__dateTakenCell', {
								'LeftNavigation__tableCell--hovered': isHovered,
								'LeftNavigation__tableCell--selected': isSelected,
							})

							return (
								<React.Fragment key={`img-file-${image.id}`}>
									<div
										className={sessionNameCSS}
										title={sessionName}
										onMouseEnter={() => onFileHovered(image.id)}
										onMouseLeave={() => onFileHovered(0)}
										onClick={() => onFileSelected(index)}>
										{sessionName}
									</div>
									<div
										className={fileNameCSS}
										title={fileName}
										onMouseEnter={() => onFileHovered(image.id)}
										onMouseLeave={() => onFileHovered(0)}
										onClick={() => onFileSelected(index)}>
										{fileName}
									</div>
									<div
										className={dateTakenCSS}
										title={image.dateTaken}
										onMouseEnter={() => onFileHovered(image.id)}
										onMouseLeave={() => onFileHovered(0)}
										onClick={() => onFileSelected(index)}>
										{dateTaken}
									</div>
								</React.Fragment>
							)
						})}
					</div>
				</div>
			)}
		</div>
	)
}

export default LeftNavigation
