import React, { useState } from 'react'
import ImageSlider from './components/ImageSlider'
import LeftNavigation from './components/LeftNavigation'
import {
	IMAGES_FILTER_ALL,
	IMAGES_FILTER_LABELED,
	IMAGES_FILTER_UNLABELED,
	ANNOTATION_TYPES,
	IMAGE_STATUS_LOCKED,
	IMAGE_STATUS_UNLOCKED,
} from './utils'

import './utils/utils.scss'

function App() {
	const [selectedType, setSelectedType] = useState(ANNOTATION_TYPES.FACE)
	const [labeledCount, setLabeledCount] = useState(0)
	const [unlabeledCount, setUnlabeledCount] = useState(0)
	const [filter, setFilter] = useState(IMAGES_FILTER_ALL)
	const [isNavigationOpened, setIsNavigationOpened] = useState(true)
	const [allImages, setAllImages] = useState([])
	const [images, setImages] = useState([])
	const [currentImageIndex, setCurrentImageIndex] = useState(0)
	const [selectedImageIndex, setSelectedImageIndex] = useState(-1)
	const [selectedRoute, setSelectedRoute] = useState({})
	const [autoCloseNavigation, setAutoCloseNavigation] = useState(0)
	const [autoShowNavigation, setAutoShowNavigation] = useState(0)

	const onTypeChanged = type => {
		setSelectedType(type)
	}

	const onUpdateImages = images => {
		const labeledCount = images.filter(i => i.status === IMAGE_STATUS_LOCKED).length
		const unlabeledCount = images.filter(i => i.status === IMAGE_STATUS_UNLOCKED).length

		setLabeledCount(labeledCount)
		setUnlabeledCount(unlabeledCount)
	}

	const onUpdateFilter = e => {
		setFilter(e.target.value)
	}

	const counts = {
		[IMAGES_FILTER_ALL]: labeledCount + unlabeledCount,
		[IMAGES_FILTER_LABELED]: labeledCount,
		[IMAGES_FILTER_UNLABELED]: unlabeledCount,
	}

	return (
		<div>
			<LeftNavigation
				selectedType={selectedType}
				onTypeChanged={onTypeChanged}
				counts={counts}
				filter={filter}
				setFilter={onUpdateFilter}
				setIsOpen={setIsNavigationOpened}
				images={images}
				currentImageIndex={currentImageIndex}
				selectImage={setSelectedImageIndex}
				setSelectedRoute={setSelectedRoute}
				allImages={allImages}
				autoCloseNavigation={autoCloseNavigation}
				autoShowNavigation={autoShowNavigation}
			/>
			<ImageSlider
				selectedType={selectedType}
				onUpdateImages={onUpdateImages}
				filter={filter}
				isNavigationOpened={isNavigationOpened}
				setImages={setImages}
				setCurrentImageIndex={setCurrentImageIndex}
				selectedImageIndex={selectedImageIndex}
				selectedRoute={selectedRoute}
				setAllImages={setAllImages}
				autoCloseNavigation={autoCloseNavigation}
				setAutoCloseNavigation={setAutoCloseNavigation}
				autoShowNavigation={autoShowNavigation}
				setAutoShowNavigation={setAutoShowNavigation}
			/>
		</div>
	)
}

export default App
