export const ANNOTATION_TYPE_FACE = 'face'
export const ANNOTATION_TYPE_LICENSE_PLATE = 'license_plate'
export const ANNOTATION_TYPE_BACKGROUND = 'background'

export const ANNOTATION_TYPES = {
	FACE: {
		value: ANNOTATION_TYPE_FACE,
		name: 'Face',
		color: 'red',
		style: {
			backgroundColor: 'rgba(245, 39, 43, 0.3)',
			border: '2px solid rgba(245, 39, 43, 1)',
		},
	},
	LICENSE_PLATE: {
		value: ANNOTATION_TYPE_LICENSE_PLATE,
		name: 'License Plate',
		color: 'purple',
		style: {
			backgroundColor: 'rgba(168, 39, 245, 0.3)',
			border: '2px solid rgba(168, 39, 245, 1)',
		},
	},
	BACKGROUND: {
		value: ANNOTATION_TYPE_BACKGROUND,
		name: 'Background',
		color: 'silver',
		style: {
			backgroundColor: 'rgba(139, 139, 139, 0.3)',
			border: '2px solid rgba(139, 139, 139, 1)',
		},
	},
}

export const getAnnotationNameFormType = type => {
	const annotation = Object.values(ANNOTATION_TYPES).find(t => t.value === type)

	return annotation?.name || type
}

export const IMAGE_STATUS_LOCKED = 'locked'
export const IMAGE_STATUS_UNLOCKED = 'unlocked'

export const IMAGES_FILTER_ALL = 'All'
export const IMAGES_FILTER_LABELED = 'Labeled'
export const IMAGES_FILTER_UNLABELED = 'Unlabeled'

export const IMAGES_FILTERS = [IMAGES_FILTER_ALL, IMAGES_FILTER_LABELED, IMAGES_FILTER_UNLABELED]

export function sortArray(arr, sortKey = null, order = 'asc') {
	const sign = order === 'asc' ? 1 : -1

	if (!arr?.length) {
		return arr
	}

	return arr.sort((a, b) => {
		let elementA = a
		let elementB = b

		if (
			(typeof elementA === 'object' && !Array.isArray(elementA)) ||
			(typeof elementB === 'object' && !Array.isArray(elementB))
		) {
			elementA = a[sortKey]
			elementB = b[sortKey]
		}

		if (!elementA && !!elementB) {
			return 1 * sign
		}

		if (!!elementA && !elementB) {
			return -1 * sign
		}

		if (elementA > elementB) {
			return 1 * sign
		} else if (elementA < elementB) {
			return -1 * sign
		} else {
			return 0
		}
	})
}
