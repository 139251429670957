import Request from './request'

const API_CITYSERVER_SERVER = process.env.REACT_APP_API_CITYSERVER_SERVER

export const getAllAppImages = () => {
	const url = `${API_CITYSERVER_SERVER}/v1/getAllAppImages`
	return Request.get({ url, headers: { 'Content-Type': 'application/json' } })
}

export const addImageSelectedArea = data => {
	const url = `${API_CITYSERVER_SERVER}/v1/addImageSelectedArea`

	return Request.post({ url, headers: { 'Content-Type': 'application/json' }, data })
}

export const updateImageSelectedArea = data => {
	const url = `${API_CITYSERVER_SERVER}/v1/updateImageSelectedArea`

	return Request.post({ url, headers: { 'Content-Type': 'application/json' }, data })
}

export const deleteImageSelectedArea = data => {
	const url = `${API_CITYSERVER_SERVER}/v1/deleteImageSelectedArea`

	return Request.put({ url, headers: { 'Content-Type': 'application/json' }, data })
}

export const updateImageStatus = data => {
	const url = `${API_CITYSERVER_SERVER}/v1/updateImageStatus`

	return Request.put({ url, headers: { 'Content-Type': 'application/json' }, data })
}
